import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Box, IconButton, ListItemButton, Tooltip } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export default function Suggestions({ data, handleClick, handleQuickSubmit }) {
  return (
      <List sx={{ width: "100%", maxHeight: "100%", overflowY: "auto" }}>
        {data.map((element, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <Divider component="li" />}
              <Box sx={{ display: "flex" }}>
                <ListItemButton
                    alignItems="flex-start"
                    onClick={() => handleClick(element.text, element.source)}
                >
                  <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                          >
                            {element.text} {/* Display the suggestion text */}
                          </Typography>
                        </React.Fragment>
                      }
                  />
                </ListItemButton>
                <Tooltip title="Quick Submit">
                  <IconButton
                      sx={{ borderRadius: "0" }}
                      onClick={() => handleQuickSubmit(element.text, element.source)}
                      color="primary"
                  >
                    <DoneAllIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </React.Fragment>
        ))}
      </List>
  );
}

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyDhdJh4x74PvHI6URM-pr0-eLulpf2RYFU",
//   authDomain: "ts-octowit-dev.firebaseapp.com",
//   projectId: "ts-octowit-dev",
//   storageBucket: "ts-octowit-dev.appspot.com",
//   messagingSenderId: "708727287067",
//   appId: "1:708727287067:web:0517a378de74693498b7be",
//   measurementId: "G-1XZ2YDFQEB",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDwo7KS3VeaV2ft-hXhiUTFDd64iG4P3M8",
  authDomain: "comic-translation-tool-proj.firebaseapp.com",
  projectId: "comic-translation-tool-proj",
  storageBucket: "comic-translation-tool-proj.appspot.com",
  messagingSenderId: "277658547213",
  appId: "1:277658547213:web:ecdf32945ad917acb85144"
};



const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");
const auth = getAuth(app);


export { auth, googleProvider, facebookProvider, microsoftProvider };
